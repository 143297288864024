body {
    font-family: 'Arial', sans-serif;
}

#home-button
{
    float:left;
}
td
{
    padding: 7px;
}

.home-button
{
    float: left;
    margin: 5px;
}

.main_nav
{
    background: #116b02; /* Old browsers */
    background: -moz-linear-gradient(left, #116b02 0%, #0ec10e 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #116b02 0%,#0ec10e 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #116b02 0%,#0ec10e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#116b02', endColorstr='#0ec10e',GradientType=1 ); /* IE6-9 */
    height: 100px;
}
.flag_background
{
    background-image: url('/images/flag.png');
    height:264px;
    width:262px;
}

.main_nav_bar .navbar-brand>img {
    height:70px;
}

.main_nav_bar_icon {
    padding-right: 5px;
}

.shadow {
    -webkit-box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.35);
    -moz-box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.35);
    box-shadow: 10px 10px 5px -4px rgba(0,0,0,0.35);
}


.rounded {
    border-radius: 53px 53px 53px 53px;
    -moz-border-radius: 53px 53px 53px 53px;
    -webkit-border-radius: 53px 53px 53px 53px;
    border: 0px solid #000000;
}

.slight-rounded {
    border-radius: 20px 20px 20px 20px;
    -moz-border-radius: 20px 20px 20px 20px;
    -webkit-border-radius: 20px 20px 20px 20px;
    border: 0px solid #000000;
}


.stats
{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b2b2b2+0,e8e8e8+100 */
    background: #b2b2b2; /* Old browsers */
    background: -moz-linear-gradient(left,  #b2b2b2 0%, #e8e8e8 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #b2b2b2 0%,#e8e8e8 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #b2b2b2 0%,#e8e8e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b2b2b2', endColorstr='#e8e8e8',GradientType=1 ); /* IE6-9 */
    
    width: 300px;
    text-align: center;
    padding: 8px;
    font-size: 25px;
    font-weight: bold;
}

.col-centered{
    float: none;
    margin: 0 auto;
}

.green-emphasis
{
    color: #3a8104;
    font-weight: bold;
}

.printbreak {
    page-break-before: always;
  }
@media print {
    tr.printbreakx {
        page-break-after: always;
        display: block;

    }
}
.strikethrough
{
    text-decoration: line-through;
}

.switch {
    position: relative;
    display: inline-block;
    width: 42px;
    height: 24px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  /* scrollable tables */
  .fixed_header tbody{
    display:block;
    overflow:auto;
    height:200px;
    width:100%;
  }
  .header_white {
    color: #ffffff;
  }

